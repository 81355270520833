import { DropdownItem } from '~/shared/components';
import { SelectedFacet } from '../../model';
import { isSameFacetItem } from '../utils';

export enum FILTER_DEBOUNCE {
    DEBOUNCE = 1200,
}

export type FilterType =
    | 'MULTI_CHECKBOX'
    | 'BOOLEAN'
    | 'RANGE'
    | 'MULTI_RANGE'
    | 'MULTI_CHECKBOX_PLAY_ACTIVITIES'
    | 'SPACE'
    | 'MULTI_CHECKBOX_WEB_GENERAL_TAGS'
    | null;

export const mapRelewiseFacetTypeToFilter = (relewiseType: string, key?: string): FilterType => {
    let type: FilterType;

    switch (true) {
        // Boolean is not allowed as a localized field in Relewise, so these facets contain literal "True" & "False" as string values.
        case key == 'IsInclusive' || key == 'QuickSupply':
            type = 'BOOLEAN';
            break;
        case key === 'WebGeneralTags':
            type = 'MULTI_CHECKBOX_WEB_GENERAL_TAGS';
            break;
        case key == 'PlayActivities':
            type = 'MULTI_CHECKBOX_PLAY_ACTIVITIES';
            break;

        case key?.startsWith('SafetyZoneAreaWidth_'):
        case key?.startsWith('SafetyZoneAreaLength_'):
            type = null;
            break;

        case key?.startsWith('SafetyZoneArea_'):
            type = 'SPACE';
            break;

        case relewiseType.includes('ProductDataBooleanValueFacet'):
            type = 'BOOLEAN';
            break;

        case relewiseType.includes('PriceRangeFacet'):
            type = 'RANGE';
            break;

        case relewiseType.includes('RangesFacetResult'):
            type = 'MULTI_RANGE';
            break;

        default:
            type = 'MULTI_CHECKBOX';
            break;
    }

    return type;
};

export const toggleFacetOption = (
    selectedFacets: SelectedFacet[],
    newItem: DropdownItem,
    attribute: string,
): SelectedFacet[] => {
    const newSelectedFacets = [...selectedFacets];
    const facetIndex = newSelectedFacets.findIndex(
        (selectedFacet) => selectedFacet.attribute === attribute,
    );

    if (facetIndex > -1) {
        const facet = newSelectedFacets[facetIndex];
        const optionSelectedIndex = facet.items.findIndex((item) => isSameFacetItem(item, newItem));

        if (optionSelectedIndex > -1) {
            facet.items.splice(optionSelectedIndex, 1);
        } else {
            facet.items.push(newItem);
        }
    } else {
        newSelectedFacets.push({
            attribute: attribute,
            items: [newItem],
        });
    }

    newSelectedFacets.forEach((newSelectedFacet) => {
        if (newSelectedFacet.items && newSelectedFacet.items.length > 0) {
            newSelectedFacet.items.sort((a, b) => {
                if (a.label && b.label) {
                    return a.label.localeCompare(b.label);
                }
                return 0;
            });
        } else {
            newSelectedFacet.items = [];
        }
    });

    return newSelectedFacets;
};
