import { Text } from '$shared/components';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import React, { useRef, useState } from 'react';
import { StyledArrow, StyledContent } from './styled';
import { useClickAway } from 'react-use';

export type TooltipProps = {
    children: React.ReactNode;
    content: React.ReactNode;
    delayDuration?: number;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
    position?: 'left' | 'right' | 'top' | 'bottom';
    contentWidth?: number;
    marginBottom?: number;
    marginTop?: number;
};
export const Tooltip = ({
    children,
    content,
    delayDuration = 0,
    position = 'top',
    contentWidth = 220,
    marginBottom,
    marginTop,
}: TooltipProps) => {
    // Undefined to prevent overwriding default behaivour
    const [isPressed, setIsPressed] = useState(false);
    const tooltipRootRef = useRef(null);

    const onTouchStartHandler = (e: React.TouchEvent<HTMLElement>) => {
        e.stopPropagation();
        if (!isPressed) {
            setIsPressed(true);
        } else {
            setIsPressed(false);
        }
    };

    const onClickHandler = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
    };

    const onClickContent = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        // Will close content on click for touch only since desktop still has hover
        if (isPressed) {
            setIsPressed(false);
        }
    };

    useClickAway(tooltipRootRef, () => {
        onClickOutside();
    });

    const onClickOutside = () => {
        if (isPressed) {
            setIsPressed(false);
        }
    };

    return (
        <span ref={tooltipRootRef}>
            <TooltipPrimitive.Provider>
                <TooltipPrimitive.Root delayDuration={delayDuration} open={isPressed || undefined}>
                    <TooltipPrimitive.Trigger
                        asChild
                        onClick={onClickHandler}
                        onTouchStart={onTouchStartHandler}
                    >
                        {children}
                    </TooltipPrimitive.Trigger>

                    <StyledContent
                        forceMount
                        onPointerDownOutside={() => onClickOutside()}
                        sideOffset={5}
                        side={position}
                        align={'center'}
                        contentWidth={contentWidth}
                        marginBottom={marginBottom}
                        marginTop={marginTop}
                        onClick={onClickContent}
                    >
                        <StyledArrow width="20" height="10" />
                        <Text variant="bodySm" as="span">
                            {content}
                        </Text>
                    </StyledContent>
                </TooltipPrimitive.Root>
            </TooltipPrimitive.Provider>
        </span>
    );
};
