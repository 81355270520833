import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import { shouldNotForwardProps } from '~/shared/utils/styled';
import { ifProp } from 'styled-tools';

const slideUpAndFade = keyframes({
    '0%': { opacity: 0, transform: 'translateY(2px)' },
    '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideRightAndFade = keyframes({
    '0%': { opacity: 0, transform: 'translateX(-2px)' },
    '100%': { opacity: 1, transform: 'translateX(0)' },
});

const slideDownAndFade = keyframes({
    '0%': { opacity: 0, transform: 'translateY(-2px)' },
    '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideLeftAndFade = keyframes({
    '0%': { opacity: 0, transform: 'translateX(2px)' },
    '100%': { opacity: 1, transform: 'translateX(0)' },
});

export const StyledContent = styled(TooltipPrimitive.Content, {
    shouldForwardProp: shouldNotForwardProps(['contentWidth']),
})<{ contentWidth?: number; marginBottom?: number; marginTop?: number }>(
    ({ theme, contentWidth }) => ({
        position: 'relative',
        padding: `${theme.spaces[4]} ${theme.spaces[6]}`,
        borderRadius: '5px',
        lineHeight: '1',
        maxWidth: contentWidth,
        backgroundColor: theme.colors.white,
        zIndex: 9,
        boxShadow: '0px 7px 25px -12px rgba(0, 0, 0, 0.20)',
        '@media (prefers-reduced-motion: no-preference)': {
            animationDuration: '400ms',
            animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
            willChange: 'transform, opacity',
            '&[data-state="delayed-open"]': {
                '&[data-side="top"]': { animationName: slideDownAndFade },
                '&[data-side="right"]': { animationName: slideLeftAndFade },
                '&[data-side="bottom"]': { animationName: slideUpAndFade },
                '&[data-side="left"]': { animationName: slideRightAndFade },
            },
        },
    }),
    ifProp('marginBottom', ({ marginBottom }) => ({
        marginBottom: marginBottom,
    })),
    ifProp('marginTop', ({ marginTop }) => ({
        marginTop: marginTop,
    })),
);

export const StyledArrow = styled(TooltipPrimitive.Arrow)(({ theme }) => ({
    fill: theme.colors.white,
}));
