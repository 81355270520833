import { DropdownItem } from '~/shared/components';
import { RelewiseRangesType } from '../requests';

export const isRangesFacet = (option: DropdownItem) => {
    return (
        typeof option.value == 'object' &&
        ('lowerBoundInclusive' in option.value || 'upperBoundExlusive' in option.value) &&
        ((option.value as RelewiseRangesType)?.lowerBoundInclusive ||
            (option.value as RelewiseRangesType)?.upperBoundExclusive)
    );
};
