import {
    BrandType,
    Facet,
    RelewiseRangesType,
    RelewiseResponseFacets,
    RelewiseResponseFacetsAvailableOption,
} from '$templates/blocks/components/M140ProductsList';
import { mapRelewiseFacetTypeToFilter } from '../../filters/utils';

import { DropdownItem } from '~/shared/components';

export const mapResponseFacets = (responseFacets: RelewiseResponseFacets[]): Facet[] =>
    responseFacets.map((facet) => {
        const { key, field, available, $type } = facet;
        const displayType = mapRelewiseFacetTypeToFilter($type, key) || undefined;

        const isRange = $type.includes('RangeFacet');
        const isIntervals = $type.includes('RangesFacet');
        const isBrand = $type.includes('BrandFacet') || $type.includes('CategoryFacet');

        const sortMethod = getSortMethod(facet);

        const items =
            !isRange && Array.isArray(available)
                ? available.map((option) => mapOption(option, isIntervals, isBrand))
                : undefined;

        return {
            displayType,
            key: key || field,
            attribute: key || field,
            type: isRange ? 'range' : 'options',
            items: items ? items.sort(sortMethod) : undefined,
            range: isRange ? mapRange(available) : undefined,
            label: key || field,
        };
    });

const mapRange = (available: RelewiseResponseFacets['available']) => {
    if (!Array.isArray(available)) {
        return {
            min: available.value?.lowerBoundInclusive,
            max: available.value?.upperBoundInclusive,
        };
    }
    return undefined;
};

const mapOption = (
    option: RelewiseResponseFacetsAvailableOption,
    isIntervals: boolean,
    isBrand: boolean
) => {
    let label = option.value;
    let value = option.value;

    if (isBrand) {
        label = (option.value as BrandType)?.displayName;
        value = (option.value as BrandType)?.id;
    }

    if (isIntervals) {
        const lower = (option.value as RelewiseRangesType)?.lowerBoundInclusive ?? 1;
        const upper = (option.value as RelewiseRangesType)?.upperBoundExclusive ?? undefined;
        label = upper ? `${lower} - ${upper - 1}` : `${lower}+`;
    }

    return ({
        label,
        value,
        count: option.hits,
    } || []) as DropdownItem;
};

const cmDropdownAsNumber = (item: DropdownItem) => {
    const value = JSON.stringify(item.value);
    // The highest range is always the same as the one below but with a "+".
    if (value.includes('+')) {
        return 9999999999;
    }
    return parseInt(value.toString().replace('0-', '').replace('+', ''));
};
const inchesDropdownAsNumber = (item: DropdownItem) => {
    const value = JSON.stringify(item.value);
    // The highest range is always the same as the one below but with a "+".
    if (value.toString().includes('+')) {
        return 9999999999;
    }
    return parseInt(value.toString().replace("'", '.').replace('+', '').replace('"', ''));
};

const getSortMethod = (facet: RelewiseResponseFacets) => {
    const isIntervals = facet.$type.includes('RangesFacet');
    const isNumeric = facet.$type.includes('ProductDataDoubleValue');
    // Some range facets are hardcoded since Relewise has limited support for localized values.
    // These are stored like "0-100" in Relewise, and needs custom sorting
    const isHardcodedRange = facet.key === 'MaxFallHeightRange';

    if (isHardcodedRange) {
        return sortHardcodedRangeFacet;
    }

    if (isNumeric) {
        return sortFacetNumeric;
    }

    if (isIntervals) {
        return sortRangesFacetNumeric;
    }

    return sortFacetAlphabetical;
};

const sortHardcodedRangeFacet = (a: DropdownItem, b: DropdownItem) => {
    // With cm values we need end value of range and sort as numeric
    if (typeof a.value !== 'string' || typeof b.value !== 'string') return 0;
    const isInches = a.value.toString().includes("'") || a.value.toString().includes('"');
    const converterFunc = isInches ? inchesDropdownAsNumber : cmDropdownAsNumber;
    return converterFunc(a) - converterFunc(b);
};

const sortFacetNumeric = (a: DropdownItem, b: DropdownItem) => {
    return parseInt(JSON.stringify(a.value)) - parseInt(JSON.stringify(b.value));
};

const sortRangesFacetNumeric = (a: DropdownItem, b: DropdownItem) => {
    if (
        typeof a.value == 'object' &&
        'lowerBoundInclusive' in a.value &&
        a.value.lowerBoundInclusive &&
        typeof b.value == 'object' &&
        'lowerBoundInclusive' in b.value &&
        b.value.lowerBoundInclusive
    ) {
        return a.value.lowerBoundInclusive - b.value.lowerBoundInclusive;
    }
    if (
        typeof a.value == 'object' &&
        'upperBoundExclusive' in a.value &&
        a.value.upperBoundExclusive &&
        typeof b.value == 'object' &&
        'upperBoundExclusive' in b.value &&
        b.value.upperBoundExclusive
    ) {
        return a.value.upperBoundExclusive - b.value.upperBoundExclusive;
    }
    return -1;
};

const sortFacetAlphabetical = (a: DropdownItem, b: DropdownItem) => {
    return (`${a.label}` || '').localeCompare(`${b.label}` || '');
};
