import {
    ContentAttributeSorting,
    ContentDataSorting,
    ContentFacetQuery,
    ContentPopularitySorting,
    ContentRelevanceSorting,
    ContentSearchRequest,
    FilterCollection,
    User,
} from '@relewise/client';
import { RelewiseContentDataTagsTypes } from '../requests';

export interface getRelewiseContentSearchQueryParams {
    locale: string;
    filters?: unknown[];
    facets?: unknown[];
    take: number;
    skip: number;
    term: string | null;
    sort?:
        | ContentAttributeSorting
        | ContentDataSorting
        | ContentPopularitySorting
        | ContentRelevanceSorting
        | null;
    user?: User;
}
export const getRelewiseContentSearchQuery = ({
    locale,
    filters,
    facets,
    take,
    skip,
    term,
    sort = undefined,
    user,
}: getRelewiseContentSearchQueryParams) => {
    const sortOption = sort ?? {
        $type: 'Relewise.Client.DataTypes.Search.Sorting.Content.ContentRelevanceSorting, Relewise.Client',
        order: 'Descending',
    };
    const searchRequest: ContentSearchRequest = {
        $type: 'Relewise.Client.Requests.Search.ContentSearchRequest, Relewise.Client',
        currency: {
            value: 'DKK',
        },
        user: user,
        language: {
            value: locale,
        },
        sorting: {
            value: sortOption,
        },
        filters: {
            items: (filters as FilterCollection['items']) || [], // TODO: convert function param to also use FilterCollection type
        },
        // @ts-expect-error ---- TODO to check error
        facets: {
            items: (facets as ContentFacetQuery['items']) || [], // TODO: convert function param to also use ContentFacetQuery type
        },
        take: take,
        skip: skip,
        term: term,
        settings: {
            $type: 'Relewise.Client.Requests.Search.Settings.ContentSearchSettings, Relewise.Client',
            recommendations: {},
            selectedContentProperties: {
                displayName: true,
                categoryPaths: false,
                assortments: true,
                allData: false,
                viewedByUserInfo: false,
                dataKeys: [
                    'Prioritized',
                    'PageReferenceTitle',
                    'PageReferenceDescription',
                    'SeoTitle',
                    'SeoDescription',
                    'Keywords',
                    'PageReferenceCompositionImage',
                    'Url',
                    RelewiseContentDataTagsTypes.ContentType,
                    RelewiseContentDataTagsTypes.L1Category,
                    RelewiseContentDataTagsTypes.L2Category,
                    RelewiseContentDataTagsTypes.Theme,
                    RelewiseContentDataTagsTypes.Sector,
                ],
            },
        },
    };

    return searchRequest;
};
