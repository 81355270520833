const formatAsCM = (distance: number) => {
    const parts = [];
    const meters = Math.floor(distance / 100);
    const cm = distance % 100;
    if (meters > 0) {
        parts.push(`${meters} m`);
    }
    if (cm != 0) {
        parts.push(`${cm} cm`);
    }

    return parts.join(' ');
};

const formatAsInch = (distance: number) => {
    const parts = [];
    let feet = Math.floor(distance / 12);
    let inches = Math.round(distance % 12);

    // to check if rounded inches are 12 and convert to 1 feet, once that 12 inches are 1 feet
    if (inches === 12) {
        feet += 1;
        inches = 0;
    }

    if (feet > 0) parts.push(`${feet}'`);

    parts.push(`${inches}"`);

    return parts.join(' ');
};

export const formatAsSQFt = (area: number) => {
    return (area / 144).toFixed(2);
};

export const formatAsSQM = (area: number) => {
    return (area / 10000).toFixed(2);
};

export const formatDistance = (distance?: number, unit?: string | null) => {
    if (!distance) return '';

    switch (unit) {
        case 'inch':
            return formatAsInch(distance);
        case 'm':
            return formatAsCM(distance * 100);
        default:
            return `${distance} ${unit}`;
    }
};
