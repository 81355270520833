import { FacetsBodyType, RelewiseProductSearchFacetTypes } from '../requests';

/**
 * 
 
AgeGroup

Capacity

SpaceRequired

Activities

Max fall height

Price (if available)

Inclusive

 */

export const staticFilters = ({
    market,
    culture,
    includeWebGeneralTags = false,
}: {
    market: string;
    culture: string;
    includeWebGeneralTags?: boolean;
}): FacetsBodyType[] => {
    const marketWithCasing = market.toUpperCase();
    return [
        ...(includeWebGeneralTags ? [webGeneralTagsFacet] : []),
        {
            $type: RelewiseProductSearchFacetTypes.PRODUCT_DATA_DOUBLE_RANGES,
            field: 'Data',
            key: `AgeGroup_from_${culture}-${marketWithCasing}`,
            dataSelectionStrategy: 'Product',
            collectionFilterType: 'Or',
            settings: {
                alwaysIncludeSelectedInAvailable: true,
            },
            predefinedRanges: [
                {
                    lowerBoundInclusive: 0,
                },
            ],
        },
        {
            $type: RelewiseProductSearchFacetTypes.PRODUCT_DATA_DOUBLE_RANGES,
            field: 'Data',
            key: 'UserCapacity',
            dataSelectionStrategy: 'Product',
            collectionFilterType: 'Or',
            settings: {
                alwaysIncludeSelectedInAvailable: true,
            },
            predefinedRanges: [
                {
                    lowerBoundInclusive: 0,
                },
            ],
        },
        {
            $type: RelewiseProductSearchFacetTypes.PRODUCT_DATA_DOUBLE_RANGES,
            field: 'Data',
            key: `SafetyZoneArea_${culture}-${marketWithCasing}`,
            dataSelectionStrategy: 'Product',
            collectionFilterType: 'Or',
            settings: {
                alwaysIncludeSelectedInAvailable: true,
            },
            predefinedRanges: [
                {
                    lowerBoundInclusive: 0,
                },
            ],
        },
        {
            $type: RelewiseProductSearchFacetTypes.PRODUCT_DATA_DOUBLE_RANGES,
            field: 'Data',
            key: `SafetyZoneAreaWidth_${culture}-${marketWithCasing}`,
            dataSelectionStrategy: 'Product',
            collectionFilterType: 'Or',
            settings: {
                alwaysIncludeSelectedInAvailable: true,
            },
        },
        {
            $type: RelewiseProductSearchFacetTypes.PRODUCT_DATA_DOUBLE_RANGES,
            field: 'Data',
            key: `SafetyZoneAreaLength_${culture}-${marketWithCasing}`,
            dataSelectionStrategy: 'Product',
            settings: {
                alwaysIncludeSelectedInAvailable: true,
            },
        },
        {
            $type: RelewiseProductSearchFacetTypes.PRODUCT_DATA_STRING,
            field: 'Data',
            key: 'PlayActivities',
            dataSelectionStrategy: 'Product',
            collectionFilterType: 'And',
            settings: {
                alwaysIncludeSelectedInAvailable: true,
            },
        },
        {
            $type: RelewiseProductSearchFacetTypes.PRODUCT_DATA_DOUBLE_RANGES,
            field: 'Data',
            key: `MaxFallHeight_${culture}-${marketWithCasing}`,
            dataSelectionStrategy: 'Product',
            collectionFilterType: 'Or',
            settings: {
                alwaysIncludeSelectedInAvailable: true,
            },
            predefinedRanges: [
                {
                    lowerBoundInclusive: 0,
                },
            ],
        },
        {
            $type: RelewiseProductSearchFacetTypes.PRICE_RANGES,
            field: 'ListPrice',
            key: 'ListPrice',
            collectionFilterType: 'Or',
            priceSelectionStrategy: 'Product',
            settings: {
                alwaysIncludeSelectedInAvailable: true,
            },
        },
        {
            $type: RelewiseProductSearchFacetTypes.PRODUCT_DATA_STRING,
            field: 'Data',
            key: 'IsInclusive',
            dataSelectionStrategy: 'Product',
            collectionFilterType: 'Or',
        },
        {
            $type: RelewiseProductSearchFacetTypes.PRODUCT_CATEGORY,
            categorySelectionStrategy: 'Ancestors',
            field: 'Category',
            collectionFilterType: 'Or', // Categories should be And, but it doesn't seem to work at the moment of writing. Separation is handled manually instead.
            selected: [] as string[],
            settings: {
                alwaysIncludeSelectedInAvailable: true,
            },
        },

        // {
        //     $type: RelewiseProductSearchFacetTypes.PRODUCT_DATA_STRING,
        //     field: 'Data',
        //     key: 'SpaceRequired',
        //     dataSelectionStrategy: 'Variant',
        //     collectionFilterType: 'Or',
        //     settings: {
        //         alwaysIncludeSelectedInAvailable: true,
        //     },
        // },
        // {
        //     $type: RelewiseProductSearchFacetTypes.PRODUCT_DATA_STRING,
        //     field: 'Data',
        //     key: 'MaxFallHeightRange',
        //     dataSelectionStrategy: 'Product',
        //     collectionFilterType: 'Or',
        //     settings: {
        //         alwaysIncludeSelectedInAvailable: true,
        //     },
        // },
        // {
        //     $type: RelewiseProductSearchFacetTypes.PRODUCT_DATA_STRING,
        //     field: 'Data',
        //     key: 'MainColor',
        //     dataSelectionStrategy: 'Variant',
        //     collectionFilterType: 'Or',
        //     settings: {
        //         alwaysIncludeSelectedInAvailable: true,
        //     },
        // },
        /* {
        $type: RelewiseProductSearchFacetTypes.PRODUCT_DATA_STRING,
        field: 'Data',
        key: 'PrimaryMaterial',
        dataSelectionStrategy: 'ProductWithFallbackToVariant',
        settings: {
            alwaysIncludeSelectedInAvailable: true,
        },
        collectionFilterType: 'Or',
    }, */
        /* 
    MaxFallheight disabled until Relewise provides support for localized Double
    {
        $type: RelewiseProductSearchFacetTypes.PRODUCT_DATA_STRING,
        field: 'Data',
        key: 'MaxFallHeightValue',
        dataSelectionStrategy: 'Product',
        collectionFilterType: 'Or',
        /* predefinedRanges: [
            {
                lowerBoundInclusive: 1,
                upperBoundExclusive: 41,
            },
            {
                lowerBoundInclusive: 41,
                upperBoundExclusive: 81,
            },
            {
                lowerBoundInclusive: 81,
                upperBoundExclusive: 121,
            },
            {
                lowerBoundInclusive: 121,
                upperBoundExclusive: 161,
            },
            {
                lowerBoundInclusive: 161,
                upperBoundExclusive: 201,
            },
            {
                lowerBoundInclusive: 201,
                upperBoundExclusive: 241,
            },
            {
                lowerBoundInclusive: 241,
                upperBoundExclusive: 281,
            },
            {
                lowerBoundInclusive: 281,
                upperBoundExclusive: 321,
            },
            {
                lowerBoundInclusive: 321,
            },
        ],
        settings: {
            alwaysIncludeSelectedInAvailable: true,
        },
    },
    */
        // {
        //     $type: RelewiseProductSearchFacetTypes.PRODUCT_DATA_BOOLEAN,
        //     field: 'Data',
        //     key: 'IsEcoVariant',
        //     dataSelectionStrategy: 'Variant',
        //     collectionFilterType: 'Or',
        // },
        // {
        //     $type: RelewiseProductSearchFacetTypes.PRODUCT_DATA_STRING,
        //     field: 'Data',
        //     key: 'QuickSupply',
        //     dataSelectionStrategy: 'Product',
        //     collectionFilterType: 'Or',
        // },
    ];
};

export const webGeneralTagsFacet = {
    $type: RelewiseProductSearchFacetTypes.PRODUCT_DATA_STRING,
    field: 'Data',
    key: 'WebGeneralTags',
    dataSelectionStrategy: 'Product',
    collectionFilterType: 'Or',
};

// List of facet buttons to display on product list
export const facetButtons: string[] = [
    'WebGeneralTags',
    'KeyUserGroupFrom',
    'UserCapacity',
    'MaxFallHeightRange',
];

// List of facets hidden from the UI. These will have to handled manually.
export const hiddenFacets: string[] = ['Category'];
