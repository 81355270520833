export const dataFieldValueContainsFilter = ({
    key,
    target = 'product',
    matchType = 'Any',
    value,
    equals = false,
}: {
    /**
     * Key of the data field
     */
    key: string;
    /**
     * Target product or content
     */
    target: 'product' | 'content';
    /**
     * Match all conditions or any
     */
    matchType: 'All' | 'Any';
    /**
     * Value or values to match
     */
    value: string[];
    /**
     *  true if strict comparisson or false if contains.
     */
    equals: boolean;
}) => {
    const conditionType = equals
        ? 'Relewise.Client.Requests.Conditions.EqualsCondition, Relewise.Client'
        : 'Relewise.Client.Requests.Conditions.ContainsCondition, Relewise.Client';
    const targetType = target == 'product' ? 'ProductDataFilter' : 'ContentDataFilter';
    return {
        $type: `Relewise.Client.Requests.Filters.${targetType}, Relewise.Client`,
        key: key,
        filterOutIfKeyIsNotFound: true,
        mustMatchAllConditions: false,
        conditions: {
            items: value.map((item) => ({
                $type: conditionType,
                value: {
                    type: 'String',
                    value: item,
                },
                valueCollectionEvaluationMode: matchType,
                negated: false,
            })),
        },
        negated: false,
    };
};

export const excludeIdFilter = (ids: string[], type: 'Product' | 'Content' = 'Content') => {
    const relewiseType = type == 'Content' ? 'ContentIdFilter' : 'ProductIdFilter';
    return {
        $type: `Relewise.Client.Requests.Filters.${relewiseType}, Relewise.Client`,
        contentIds: ids,
        negated: true,
    };
};

export const noEmptyDataFieldFilter = (key: string, target: 'product' | 'content' = 'product') => {
    const type = target == 'product' ? 'ProductDataFilter' : 'ContentDataFilter';
    return {
        $type: `Relewise.Client.Requests.Filters.${type}, Relewise.Client`,
        key: key,
        filterOutIfKeyIsNotFound: true,
        mustMatchAllConditions: true,
        conditions: {
            items: [
                {
                    $type: 'Relewise.Client.Requests.Conditions.EqualsCondition, Relewise.Client',
                    value: {
                        type: 'String',
                        value: null,
                    },
                    negated: true,
                },
                {
                    $type: 'Relewise.Client.Requests.Conditions.EqualsCondition, Relewise.Client',
                    value: {
                        type: 'String',
                        value: '',
                    },
                    negated: true,
                },
                {
                    $type: 'Relewise.Client.Requests.Conditions.EqualsCondition, Relewise.Client',
                    value: {
                        type: 'String',
                        value: '""',
                    },
                    negated: true,
                },
            ],
        },
        negated: false,
    };
};

export const specificProductIdsFilter = (ids: string[] = []) => {
    return {
        $type: 'Relewise.Client.Requests.Filters.ProductIdFilter, Relewise.Client',
        productIds: ids,
        negated: false,
    };
};

export const specificProductCategoryIdsFilter = (categories: string[] = []) => ({
    $type: 'Relewise.Client.Requests.Filters.ProductCategoryIdFilter, Relewise.Client',
    evaluationScope: 'Ancestor',
    categoryIds: categories,
    negated: false,
    settings: null,
});
