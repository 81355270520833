import { UserFactory, Tracker, User } from '@relewise/client';
import { isSSR } from '~/shared/utils';
import { publicRuntimeConfig } from '~/shared/utils/public-variables';
import { getCookie, hasCookie } from 'cookies-next';
const SECOND_IN_MS = 1000;

export const useRelewiseTracking = () => {
    const { RELEWISE_ENVIRONMENT_ID, RELEWISE_SEARCH_API_KEY } = publicRuntimeConfig();

    const tracker = new Tracker(RELEWISE_ENVIRONMENT_ID, RELEWISE_SEARCH_API_KEY);

    const getMarketingConsent = () => {
        return window?.CookieInformation?.getConsentGivenFor('cookie_cat_marketing');
    };

    const getUserObject = (): User => {
        let user = UserFactory.anonymous();
        if (!hasCookie('CookieInformationConsent')) return user;

        try {
            const cookieRawValue = getCookie('CookieInformationConsent') as string;
            const cookieParsed = JSON.parse(cookieRawValue);
            const hasConsent = cookieParsed?.consents_approved?.includes('cookie_cat_marketing');
            const userId = cookieParsed?.user_uid;
            if (userId && !!hasConsent) {
                user = UserFactory.byTemporaryId(userId);
            }
        } catch (err) {
            // Catch is ignored, user default value is already set.
        }

        return user;
    };

    const getUser = async () => {
        return new Promise<User>((resolve) => {
            // can't resolve cookie information in SSR
            if (isSSR) {
                return resolve(UserFactory.anonymous());
            }

            const hasConsent = getMarketingConsent();
            if (hasConsent == undefined) {
                // Await CookieInformation being ready as set user then.
                window.addEventListener(
                    'CookieInformationConsentGiven',
                    () => {
                        resolve(getUserObject());
                    },
                    false,
                );
                // If CookieInformation doesn't get ready in specified time, use anonymous instead
                setTimeout(() => {
                    resolve(getUserObject());
                }, 5 * SECOND_IN_MS);
                return;
            }
            // All is good and ready, do tracking regularly.
            resolve(getUserObject());
        });
    };

    const trackProductView = async (productId: string) => {
        const user = await getUser();
        await tracker.trackProductView({
            productId: productId,
            user,
        });
    };

    const trackProductCategory = async (categoryIds: string[]) => {
        const user = await getUser();
        await Promise.all(
            categoryIds.map(async (categoryId) => {
                return await tracker.trackProductCategoryView({
                    idPath: [categoryId],
                    user,
                });
            }),
        );
    };

    const trackContentView = async (contentId: string) => {
        const user = await getUser();
        await tracker.trackContentView({
            contentId,
            user,
        });
    };

    return {
        getUser,
        getUserObject,
        trackProductView,
        trackProductCategory,
        trackContentView,
    };
};
