import { DropdownItem } from '~/shared/components';
import { RelewiseRangesType } from '../requests';
import { isRangesFacet } from './isRangesFacet';

export const isSameFacetItem = (a: DropdownItem, b: DropdownItem) => {
    const isRangeA = isRangesFacet(a);
    const isRangeB = isRangesFacet(b);
    if (isRangeA != isRangeB) return false;
    if (isRangeA && isRangeB) {
        return (
            (a.value as RelewiseRangesType)?.lowerBoundInclusive ==
                (b.value as RelewiseRangesType)?.lowerBoundInclusive &&
            (a.value as RelewiseRangesType)?.upperBoundExclusive ==
                (b.value as RelewiseRangesType)?.upperBoundExclusive
        );
    }

    return a.value == b.value;
};
